import React from "react";
import ContactForm from "../contact-form/contact-form";
import "./form-container.scss";

const FormContainer = ({ topic = "", pagePath }) => {
  return (
    <div className="my-form-container">
      <h2 className="my-form-container__title">FIND OUT HOW OUR {topic && "YACHT"} <strong className="my-form-container__title-accent">{topic}</strong> TEAM CAN ASSIST YOU</h2>
      <p className="my-form-container__subtitle">Please complete the form below to Email us and our {topic && "Yacht"} {topic} team will respond to your inquiry shortly.</p>
      <ContactForm pagePath={pagePath} />
    </div>
  );
}

export default FormContainer;